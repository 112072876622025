import * as url from "../constant/Endpoints";
import axios from "axios";
import { toast } from "react-toastify";
import { objectToQueryString } from "helper/urlquerystring";
import { AES, enc } from "crypto-js";

import "react-toastify/dist/ReactToastify.css"; // Make sure you're importing the CSS file as well
import {
  GetAllProperty,
  GetPropertyType as getAllPropertyAgain,
  getAllNotification,
  FetchAllRoleView as FetchAllRoleViewAgain,
  GetAllMaintenanceType,
  GetAllMaintenance as GetAllMaintenanceAgain,
} from "store/action/index";
import { GetAllAmenities as getAllAmenitiesAgain } from "store/action/index";
import { GetAllFlatSize as getAllFlatSizeAgain } from "store/action/index";
import { GetAllFlat as getAllFlatAgain } from "store/action/index";
import { GetAllTaxes as getAllTaxesAgain } from "store/action/index";
import { dispatch } from "store/index";
const CryptoJS = require("crypto-js");

export default {
  // ========================================= property managment ===================================

  fetchLoginUser: (payload, cb) => {
    if (payload.email === "" || payload.password === "") {
      payload.setLoading(false);

      return false;
    }

    const data = {
      email: payload.email,
      password: payload.password,
    };

    PostData(url.base_url + url.logInApii, data)
      .then((res) => {
        const encryptedToken = CryptoJS.AES.encrypt(
          res.data.data.token,
          url.encryptionSecret
        ).toString();

        const encryptedLoginData = CryptoJS.AES.encrypt(
          JSON.stringify(res?.data?.data),
          url.encryptionSecret
        ).toString();
        localStorage.setItem("token", encryptedToken);
        localStorage.setItem("Id", res.data.data.id);
        // localStorage.setItem("LoginData", JSON.stringify(res?.data?.data));
        localStorage.setItem("data", encryptedLoginData);
        if (res?.data?.data?.user_type == "bank_user") {
          window.location.reload();
          window.location.href = "/payments/all-payments";
        } else {
          window.location.reload();
          window.location.href = "/dashboard";
        }
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);

        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.data?.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  ForgetPassword: (payload, cb) => {
    if (payload.email === "") {
      payload.setLoading(false);

      return false;
    }

    const data = {
      email: payload.email,
    };

    PostData(url.base_url + url.ForgetPassword, data)
      .then((res) => {
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        payload.setLoading(false);
        setTimeout(() => {
          localStorage.setItem("email-forget", payload.email);
          window.location = "/otp-password";
        }, 1000);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  VerifyOTP: (payload, cb) => {
    if (
      payload.email === "" ||
      payload.password === "" ||
      payload.c_password === ""
    ) {
      payload.setLoading(false);

      return false;
    }
    if (payload.password !== payload.c_password) {
      toast.error("New Password & Confirm Password does not match", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
      payload.setLoading(false);
      return false;
    }

    const data = {
      email: payload.email,
      otp: payload.otp,
      password: payload.password,
      c_password: payload.c_password,
    };

    PostData(url.base_url + url.VerifyOTP, data)
      .then((res) => {
        toast.success(res?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        localStorage.removeItem("email-forget");
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  // updateVerfiyLimit: (payload, cb) => {
  //   const NewData = new FormData();
  //   // NewData.append('id', payload.id)
  //   NewData.append("id", payload.product_id);
  //   NewData.append("limit", payload.limit);

  //   PostRegisterData(url.base_url + url.updateVerfiyLimit, NewData)
  //     .then((res) => {
  //       payload.setLoading(false);

  //       toast.success("Verify limit Update Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       window.location.reload();

  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       payload.setLoading(false);
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       cb(err?.response?.status);
  //     });
  // },

  // <------------------------------------------Property----------------------------------------------------->

  CreateAllProperty: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createAllProperty, payload?.payload)
      .then((res) => {
        toast.success("Property  Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(GetAllProperty({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  FetchReportsByMonths: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.reportBynonthapi + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  FetchReportActionReportFlatShops: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url +
        url.reeportbyyyflatspremieses +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  FetchReportActionPropertyy: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url +
        url.reportbypropertyyyapi +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  FetchAllRepotsData: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.allreportApi + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  FetchReportByClientss: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url +
        url.reportbyclientapi +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  FetchhReportssByPaymentes: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url +
        url.reportbypaymentapi +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  UpdateAllProperty: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.updateAllProperty, payload?.payload)
      .then((res) => {
        toast.success("Property  Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(GetAllProperty({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetAllProperty: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllProperty)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetAllActiveTax: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllActiveTax)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetPropertyPremesis: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      ` ${url.base_url}${url.getAllFlatProperty}${
        payload?.property_id ? `?property_id=${payload?.property_id}` : ""
      }   ${
        payload?.property_status
          ? `?property_status=${payload?.property_status}`
          : ""
      }    `
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  AllFetchAssignPermiss: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url +
        url.assignpermissionapiGet +
        objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  // AllFetchAssignPermiss: (payload, cb) => {
  //   payload?.setLoading(true);
  //   getData(
  //     `${url.base_url + url.assignpermissionapiGet}${
  //       payload?.pagination ? "?page=" + payload?.pagination : ""
  //     }`
  //   )
  //     .then((res) => {
  //       payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       payload?.setLoading(false);

  //       cb(err?.response?.status);
  //     });
  // },

  AllAssPreDetails: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.assinPreDeatilsss + payload?.id)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetAllActiveProperty: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllActiveProperty)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // <------------------------------------------Property----------------------------------------------------->

  // <---------- property type -------------------->

  GetPropertyType: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      `${url.base_url + url.getAllPropertyType}${
        payload?.pagination ? "?page=" + payload?.pagination : ""
      }`
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  FetchFilterPremises: (payload, cb) => {
    // getData(
    //   `${url.base_url + url.filterpremises}${'?'}${payload?.size === "all" ? "" : `${"/"+payload?.size || ""}`} ${
    //     payload?.property_id !== "" ? `property_id=${payload?.property_id || ""}` : ""
    //   } `
    // )
    getData(
      url.base_url + url.filterpremises + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetActivePropertyType: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllActivePropertyType)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  CreatePropertyType: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createPropertyType, payload?.payload)
      .then((res) => {
        toast.success("Property Type Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllPropertyAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  REmoveFetchNotifiction: (payload, cb) => {
    PostRegisterData(url.base_url + url.removeNotification + "/" + payload)
      .then((res) => {
        cb(res.data);
        dispatch(getAllNotification());
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  // CreateClientsFetch: (payload, cb) => {

  //   PostRegisterData(
  //     url.base_url + url.createCLienApi, payload?.payload
  //   )
  //     .then((res) => {
  //       toast.success("Property Type Created Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       dispatch(getAllPropertyAgain({ setLoading: payload?.setLoading }))
  //       payload?.setLoading(false);
  //       cb(res.data);

  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       payload?.setLoading(false);

  //       cb(err?.response?.status);
  //     });
  // },

  CreateClientsFetch: (payload, cb, timeout) => {
    const NewData = new FormData();

    NewData.append("client_name", payload.client_name);
    NewData.append("client_number", payload.client_number);
    NewData.append("client_govt_id", payload.client_govt_id);
    NewData.append("client_email", payload.client_email);
    NewData.append("notes", payload.notes);

    // console.log(NewData);
    PostRegisterData(url.base_url + url.createCLienApi, payload)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        toast.success("Create Template Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        // setTimeout(() => {
        //   window.location.href = "/clients/all-clients";
        // }, 800);
      })
      .catch((err) => {
        payload?.setLoading(false);
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  // CreateFetchAssignPermissiosn: (payload, cb, timeout) => {
  //   const NewData = new FormData();
  //   NewData.append("property_id", payload.property_id);
  //   NewData.append("flat_id", payload.flat_id);
  //   NewData.append("contract_date", payload.contract_date);
  //   NewData.append("contract_duration", payload.contract_duration);
  //   NewData.append("per_month", payload.per_month);
  //   NewData.append("client_id", payload.client_id);

  //   console.log(NewData);
  //   PostRegisterData(url.base_url + url.createfetchassignproperty, payload)
  //     .then((res) => {
  //       cb(res.data);
  //       payload?.setLoading(false);
  //       payload?.setIsModalOpen(false);
  //       toast.success("Client Assigned Property Successfully", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });

  //     })
  //     .catch((err) => {
  //       payload?.setLoading(false);
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       toast.error(err?.response?.data?.message, {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //     });
  // },

  CreateFetchAssignPermissiosn: (payload, cb) => {
    PostRegisterData(
      url.base_url + url.createfetchassignproperty,
      payload?.formatAllData
    )
      .then((res) => {
        toast.success("Assign Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }));

        window.location.reload();
        window.location.href = "/premises/all-assign-premises";
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  UpdatePropertyType: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.updatePropertyType, payload?.payload)
      .then((res) => {
        toast.success("Property Type Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllPropertyAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  fetchEditClients: (payload, cb) => {
    // payload?.setLoading(true);
    // console.log("dmddkkpayy", payload);
    PostRegisterData(url.base_url + url.editclinetapi, payload)
      .then((res) => {
        toast.success("Property Type Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        cb(res.data);
        // setTimeout(() => {
        //   window.location.href = "/clients/all-clients";
        // }, 800);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  UpdateStatusPropertyType: (payload, cb) => {
    // payload?.setLoading(true);

    getData(
      url.base_url +
        url.updateStatusPropertyType +
        payload?.payload?.id +
        "/" +
        payload?.payload?.status
    )
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllPropertyAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // <---------- property type -------------------->

  // <----------------------------Dashboard--------------------------->

  getAllDashboard: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllDashboard)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // ----------------------------------------------------------------------------
  getAllNotification: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.NotificationGetAll)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },
  // ----------------------------------------------------------------------------

  // <----------------------------Dashboard--------------------------->

  // <------------- Amenities ------------------->

  GetAllActiveAmenities: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllActiveAmenities)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  GetAmenitiesType: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      `${url.base_url + url.getAllAmenitiesType}${
        payload?.pagination ? "?page=" + payload?.pagination : ""
      }`
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  CreateAmenitiesType: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createAmenitiesType, payload?.payload)
      .then((res) => {
        toast.success("Amenities Type Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllAmenitiesAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  UpdateAmenitiesType: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.updateAmenitiesType, payload?.payload)
      .then((res) => {
        toast.success("Amenities Type Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllAmenitiesAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  UpdateStatusAmenitiesType: (payload, cb) => {
    // payload?.setLoading(true);

    getData(
      url.base_url +
        url.updateStatusAmenitiesType +
        payload?.payload?.id +
        "/" +
        payload?.payload?.status
    )
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllAmenitiesAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // <------------- Amenities ------------------->

  // <------------- Flat Size ------------------->

  GetFlatSize: (payload, cb) => {
    payload?.setLoading(true);

    getData(
      `${url.base_url + url.getAllFlatSize}${
        payload?.pagination ? "?page=" + payload?.pagination : ""
      }`
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  GetActiveFlatSize: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllActiveFlatSize)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  AllClientDetails: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.fetchgetclientapi + "/" + payload?.id)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetchAllClientData: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      `${url.base_url + url.fetchgetclientapi}${
        payload?.pagination ? "?page=" + payload?.pagination : ""
      }`
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  CreateFlatSize: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createFlatSize, payload?.payload)
      .then((res) => {
        toast.success("Premises Size Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllFlatSizeAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  UpdateFlatSize: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.updateFlatSize, payload?.payload)
      .then((res) => {
        toast.success("Premises Size Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllFlatSizeAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  UpdateStatusFlatSize: (payload, cb) => {
    // payload?.setLoading(true);

    getData(
      url.base_url +
        url.updateStatusFlatSize +
        payload?.payload?.id +
        "/" +
        payload?.payload?.status
    )
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllFlatSizeAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // <------------- Flat Size ------------------->

  // <------------- Flat  ------------------->

  GetFlat: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllFlat)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  GetActiveFlat: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllActiveFlat)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  CreateFlat: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createFlat, payload?.payload)
      .then((res) => {
        toast.success("Flat  Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllFlatAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
        window.location = "/flat/all-flat";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  UpdateFlat: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.updateFlat, payload?.payload)
      .then((res) => {
        toast.success("Flat  Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllFlatAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
        window.location = "/flat/all-flat";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  UpdateStatusFlat: (payload, cb) => {
    // payload?.setLoading(true);

    getData(
      url.base_url +
        url.updateStatusFlat +
        payload?.payload?.id +
        "/" +
        payload?.payload?.status
    )
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllFlatAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // <------------- Flat  ------------------->
  // <------------- maintenance ------------------->
  GetAllMaintenanceType: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllMaintenanceType)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  GetAllMaintenance: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      `${url.base_url + url.getAllMaintenance}${
        payload?.pagination ? "?page=" + payload?.pagination : ""
      }`
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  UpdateStatusMaintenance: (payload, cb) => {
    // payload?.setLoading(true);

    getData(
      url.base_url +
        url.updateStatusMaintenance +
        payload?.payload?.id +
        "/" +
        payload?.payload?.status
    )
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(GetAllMaintenanceAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  AllActiveMaintenance: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.activeMaintenanceType)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  UpdateMaintenanceType: (payload, cb) => {
    // payload?.setLoading(true);

    getData(
      url.base_url +
        url.updateMaintenance +
        payload?.payload?.id +
        "/" +
        payload?.payload?.status
    )
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(GetAllMaintenanceType());
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  EditMaintenanceType: (payload, cb) => {
    PostRegisterData(url.base_url + url.editMaintenanceType, payload?.payload)
      .then((res) => {
        toast.success("Maintenance Type Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(GetAllMaintenanceType());
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  CreateMaintenance: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createAllmaintenance, payload?.payload)
      .then((res) => {
        toast.success("Flat  Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        cb(res.data);
        window.location = "/maintenance/all-maintenance";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  UpdateMaintenance: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.updatAllmaintenance, payload?.payload)
      .then((res) => {
        toast.success("Flat  Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        cb(res.data);
        window.location = "/maintenance/all-maintenance";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  // <------------- maintenance ------------------->

  // <------------- Taxes ------------------->

  GetTaxes: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      `${url.base_url + url.getAllTaxes}${
        payload?.pagination ? "?page=" + payload?.pagination : ""
      }`
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  CreateTaxes: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createTaxes, payload?.payload)
      .then((res) => {
        toast.success("Tax Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  FetchMaintenceType: (payload, cb) => {
    PostRegisterData(url.base_url + url.createmaintenance, payload?.payload)
      .then((res) => {
        dispatch(GetAllMaintenanceType());

        toast.success("Add Successfully Maintenance Type", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  UpdateTaxes: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.updateTaxes, payload?.payload)
      .then((res) => {
        toast.success("Tax Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  UpdateStatusTaxes: (payload, cb) => {
    // payload?.setLoading(true);

    getData(
      url.base_url +
        url.updateStatusTaxes +
        payload?.payload?.id +
        "/" +
        payload?.payload?.status
    )
      .then((res) => {
        toast.success("Status Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }));
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // <------------- Taxes ------------------->

  // <-------------------- Payment ---------------->

  CreateAddPayment: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createPayment, payload?.payload)
      .then((res) => {
        toast.success("Payment Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }))
        payload?.setLoading(false);
        window.location = "/payments/all-payments";

        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // GetAllPayments: (payload, cb) => {
  //   // payload?.setLoading(true);
  //   getData(url.base_url + url.getPayment)
  //     .then((res) => {
  //       // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }))
  //       payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       payload?.setLoading(false);

  //       cb(err?.response?.status);
  //     });
  // },

  GetAllPayments: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.getPayment + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  GetPaymentDataByUniqueId: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.paymentUniqueId + payload.unique_id)
      .then((res) => {
        payload?.setLoading(false);
        payload.handleNavigate(res.data);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  // <-------------------- Payment ---------------->

  // <----------------- USERS  --------------------->

  CreateAddUsers: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.createUsers, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }))
        payload?.setLoading(false);
        cb(res.data);
        window.location = "/users/all-users";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  EditUpdateUsers: (payload, cb) => {
    // payload?.setLoading(true);

    PostRegisterData(url.base_url + url.updateUsers, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }))
        payload?.setLoading(false);
        cb(res.data);
        window.location = "/users/all-users";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  GetAllUsers: (payload, cb) => {
    // payload?.setLoading(true);
    getData(url.base_url + url.getAllUsers)
      .then((res) => {
        // dispatch(getAllTaxesAgain({ setLoading: payload?.setLoading }))
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  // <----------------- USERS  --------------------->

  //<----------------- NOTIFICATION -------------------->
  AllNotification: (payload, cb) => {
    // payload?.setLoading(true);
    getData(url.base_url + url.getAllNotification)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },
  //<----------------- NOTIFICATION -------------------->

  // ========================================= property managment ===================================

  FetchRegisterBrandOwnerr: (payload, cb) => {
    const NewData = new FormData();
    // NewData.append('id', payload.id)
    NewData.append("company_id", payload.company_id);
    NewData.append("brand_name", payload.brand_name);
    NewData.append("brand_email", payload.brand_email);
    NewData.append("brand_logo", payload.brand_logo);
    NewData.append("brand_url", payload.brand_url);
    NewData.append("brand_phone", payload.brand_phone);
    NewData.append("brand_city", payload.brand_city);
    NewData.append("brand_address", payload.brand_address);
    NewData.append("created_by", payload.created_by);

    PostRegisterData(url.base_url + url.registerBrandOwner, NewData)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  // FetchCreateCompany: (payload, cb) => {
  //   PostRegisterData(url.base_url + url.createcompany, payload)
  //     .then((res) => {
  //       cb(res.data);
  //       toast.success("Company Created Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       setTimeout(() => {
  //         window.location.href = "/company/all-company";
  //       }, 1500);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }

  //       if (err?.response?.data?.message === "Validation Error.") {
  //         const errorMessages = err?.response?.data?.data;

  //         if (errorMessages) {
  //           Object.keys(errorMessages).forEach((key) => {
  //             const messages = errorMessages[key];
  //             messages.forEach((message) => {
  //               toast.error(`${key}: ${message}`, {
  //                 position: "top-right",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: false,
  //                 draggable: true,
  //                 progress: undefined,
  //                 bodyClassName: "toastStyle",
  //               });
  //             });
  //           });
  //         }
  //       }

  //       cb(err?.response?.status);
  //       toast.error(err?.response?.data?.message, {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //     });
  // },

  CreateFetchRolee: (payload, cb) => {
    const data = payload?.payload;

    PostRegisterData(url.base_url + url.registerRollsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(FetchAllRoleViewAgain());
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },
  EditRole: (payload, cb) => {
    const data = payload.payload;

    PostRegisterData(url.base_url + url.editRoleAPI, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        dispatch(FetchAllRoleViewAgain());
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  AssignPermissionsShop: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.assignpermissionsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Permissions Assigned Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        // <------- permissions update localstorage -------------------->
        // let permissionUser = localStorage.getItem("data");

        // let decryptedData = null;
        // if (permissionUser) {
        //   try {
        //     // Code that may throw an exception
        //     decryptedData = AES.decrypt(
        //       permissionUser,
        //       url.encryptionSecret
        //     ).toString(enc.Utf8);
        //     // ...
        //   } catch (error) {
        //     localStorage.clear();
        //     window.location.reload();
        //   }
        // }
        // let permissionsArray = [];

        // try {
        //   permissionsArray = decryptedData && JSON.parse(decryptedData);
        // } catch (e) {}

        // console.log("sd: " ,res,payload,permissionsArray)
        // <------- permissions update localstorage -------------------->

        window.location.href = "/";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchRegisterFetchCategory: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.cateapplyregister, data)
      .then((res) => {
        toast.success("Category Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
      });
  },

  getAllPermissions: (payload, cb) => {
    getData(url.base_url + url.allpermissionsApi)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },
  GetRolePermissions: (payload, cb) => {
    getData(url.base_url + url.getRolePermissionsApi + `/${payload?.roleId}`)
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload.setLoading(false);
      });
  },

  FetchAllBrands: (payload, cb) => {
    getData(url.base_url + url.getAllBrands)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
      });
  },

  // Users

  HandleEditUserApi: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.edituserapis, data)
      .then((res) => {
        cb(res.data);
        toast.success("User Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  fetchRegisterBrandUserrs: (payload, cb) => {
    PostRegisterData(url.base_url + url.registerbranduser, payload)
      .then((res) => {
        cb(res.data);
        if (res?.data.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.success("User Registered Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        window.location.href = "/user-management/users";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchAllUsersView: (payload, cb) => {
    getData(url.base_url + url.allUsersApi + "?page=" + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchUsersStatusUpdateView: (payload, cb) => {
    getData(url.base_url + url.usersStatusUpdateApi + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  // Products

  HandleEditProductz: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.editProductApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Product Edited Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        window.location.href = "/products/all-products";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  ChangePassword: (payload, cb) => {
    const data = {
      current_password: payload.current_password,
      password: payload.password,
    };

    PostRegisterData(url.base_url + url.ChangePassword, data)
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
        toast.success("Password Change Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        payload.setModalOpen(false);

        window.location.href = "/dashboard";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          payload.setLoading(false);
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        payload.setLoading(false);
        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  // PostFAQSs: (payload, cb) => {
  //   const NewData = new FormData();
  //   NewData.append("content", payload.content);

  //   payload.content = payload.content.toString();
  //   PostRegisterData(url.base_url + url.UpdateFAQs, NewData)
  //     .then((res) => {
  //       cb(res.data);

  //       toast.success("FAQs Update Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       payload.setLoading(false);

  //       window.location.href = "/DomainParameter/FAQs";
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         payload.setLoading(false);

  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }

  //       payload.setLoading(false);

  //       // console.warn(err);
  //       cb(err?.response?.status);
  //       toast.error(err?.response?.data?.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //     });
  // },

  // PostTerms: (payload, cb) => {
  //   const NewData = new FormData();
  //   // NewData.append('id', payload.id)
  //   NewData.append("content", payload.content);

  //   payload.content = payload.content.toString();

  //   PostRegisterData(url.base_url + url.UpdateTerms, NewData)
  //     .then((res) => {
  //       cb(res.data);

  //       toast.success("Terms Update Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       window.location.href = "/DomainParameter/TermsAndPolicy";
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       // console.warn(err);
  //       cb(err?.response?.status);
  //       toast.error(err?.response?.data?.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //     });
  // },

  async UpdateStatusCode(payload) {
    try {
      const response =
        payload.flag === 3
          ? await PostRegisterData(url.base_url + url.update_status_code, {
              flag: payload.flag,
              id: payload.id,
              serial_start_rng: payload.serial_start_rng,
              serial_end_rng: payload.serial_end_rng,
              status: payload.status,
            })
          : await PostRegisterData(url.base_url + url.update_status_code, {
              flag: payload.flag,
              id: payload.id,
              status: payload.status,
            });

      toast.success("Deactivate Code Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });

      payload.setLoading(false);
      window.location.href = "/random/all-random-code";
    } catch (error) {
      if (error?.response?.data?.message === "Unauthenticated.") {
        localStorage.clear();
        window.location = "/";
        window.location.reload();
      }
      // Handle other errors or show an error message
      toast.error(error?.response?.data?.message || "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });

      payload.setLoading(false);
    }
  },

  // BatchProductSerialNo: (payload, cb) => {
  //   payload.setLoading(true);
  //   const apiUrl = `${url.base_url}${url.batchProductSerialNo}${payload.products}?serial_start_rng=${payload.serial_start_rng}&serial_end_rng=${payload.serial_end_rng}`;

  //   getData(apiUrl)
  //     .then((res) => {
  //       payload.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       payload.setLoading(false);
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       cb(err?.response?.status);
  //     });
  // },

  // getBatchByProductID: (payload, cb) => {
  //   payload?.setLoading(true);
  //   getData(url.base_url + url.getBatchByProductID + payload.id)
  //     .then((res) => {
  //       payload?.setLoading(false);

  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       payload?.setLoading(false);
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       cb(err?.response?.status);
  //     });
  // },

  // CsvDownload: (payload, cb) => {
  //   payload?.setLoading(true);
  //   getData(url.base_url + url.csv_url + objectToQueryString(payload?.params))
  //     .then((res) => {
  //       payload?.setLoading(false);
  //       const csvUrl = `${url.csv_file}${res.data}`;
  //       window.open(csvUrl);

  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       payload?.setLoading(false);
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       cb(err?.response?.status);
  //     });
  // },

  FetchAllActiveProducts: (payload, cb) => {
    getData(url.base_url + url.allProductActiveApi)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchAllProductz: (payload, cb) => {
    getData(
      url.base_url + url.allProductApi + objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchProductsStatusUpdateView: (payload, cb) => {
    getData(url.base_url + url.ProductStatusUpdateApi + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        // console.warn(err);
        // alert("Api error")
        // toast.error('Some Error Occoured2', {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   progress: undefined,
        //   bodyClassName: 'toastStyle',
        // });
      });
  },

  FetchBrandOnesDetail: (payload, cb) => {
    getData(url.base_url + url.brandeditapi + "/" + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  FetchCompanyDetails: (payload, cb) => {
    getData(url.base_url + url.companydetailing + "/" + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        cb(err?.response?.status);
      });
  },

  FetchAllCategeory: (payload, cb) => {
    if (payload && payload.setLoading) {
      payload.setLoading(true);
    }

    getData(
      url.base_url + url.getsallcategeory + objectToQueryString(payload?.params)
    )
      .then((res) => {
        if (payload && payload.setLoading) {
          payload.setLoading(false);
        }
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (payload && payload.setLoading) {
          payload.setLoading(false);
        }

        cb(err?.response?.status);
      });
  },

  fetchALlDataExportCSVCategoeries: (payload, cb) => {
    if (payload?.hasOwnProperty("paginate")) {
      delete payload?.paginate;
    } else if (payload?.hasOwnProperty("limit")) {
      delete payload?.limit;
    }
    getData(
      `${url.base_url}${url.getsallcategeory}?paginate=all${
        payload?.name ? `&name=${payload.name}` : ""
      }${payload?.description ? `&description=${payload.description}` : ""}${
        payload?.company_name ? `&company_name=${payload.company_name}` : ""
      }${payload?.status ? `&status=${payload.status}` : ""}`
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  // AllSubCategoryGet: (payload, cb) => {
  //   payload?.setLoading(true);

  //   getData(
  //     url.base_url +
  //       url.allsubcategroiess +
  //       objectToQueryString(payload?.params)
  //   )
  //     .then((res) => {
  //       payload?.setLoading(false);

  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       payload?.setLoading(false);

  //       cb(err?.response?.status);
  //     });
  // },

  AllCompanies: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.companygetall + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  FetchCSVCmpanyAll: (payload, cb) => {
    if (payload?.hasOwnProperty("paginate")) {
      delete payload?.paginate;
    } else if (payload?.hasOwnProperty("limit")) {
      delete payload?.limit;
    }

    getData(
      `${url.base_url}${url.companygetall}?paginate=all${
        payload?.company_code ? `&company_code=${payload.company_code}` : ""
      }${payload?.company_name ? `&company_name=${payload.company_name}` : ""}${
        payload?.company_city ? `&company_city=${payload.company_city}` : ""
      }${
        payload?.company_country
          ? `&company_country=${payload.company_country}`
          : ""
      }${
        payload?.company_email ? `&company_email=${payload.company_email}` : ""
      }${
        payload?.company_phone ? `&company_phone=${payload.company_phone}` : ""
      }${payload?.company_url ? `&company_url=${payload.company_url}` : ""}${
        payload?.role_name ? `&role_name=${payload.role_name}` : ""
      }${payload?.status ? `&status=${payload.status}` : ""}`
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
        }
        cb(err?.response?.status);
      });
  },
  FetchCSVReportProblem: (payload, cb) => {
    if (payload?.hasOwnProperty("paginate")) {
      delete payload?.paginate;
    } else if (payload?.hasOwnProperty("limit")) {
      delete payload?.limit;
    }

    getData(
      `${url.base_url}${url.ReportProblem}?paginate=all${
        payload?.uid ? `&uid=${payload.uid}` : ""
      }${payload?.reason ? `&reason=${payload.reason}` : ""}${
        payload?.email ? `&email=${payload.email}` : ""
      }${payload?.batch_name ? `&batch_name=${payload.batch_name}` : ""}${
        payload?.product_name ? `&product_name=${payload.product_name}` : ""
      }`
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
        }
        cb(err?.response?.status);
      });
  },

  AllBatches: (payload, cb) => {
    payload?.setLoading(true);
    getData(
      url.base_url + url.GetAllBatch + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  FetchMapingData: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.MapApi + objectToQueryString(payload?.params))
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);

        cb(err?.response?.status);
      });
  },

  // GetFaqs: (payload, cb) => {
  //   getData(url.base_url + url.GetFAQs)
  //     .then((res) => {
  //       payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //       }
  //       payload?.setLoading(false);

  //       cb(err?.response?.status);
  //     });
  // },

  // GetTerms: (payload, cb) => {
  //   payload?.setLoading(true);

  //   getData(url.base_url + url.GETTerms)
  //     .then((res) => {
  //       payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //       }
  //       payload?.setLoading(false);

  //       cb(err?.response?.status);
  //     });
  // },

  // AllColorShift: (payload, cb) => {
  //   payload?.setLoading(true);

  //   getData(
  //     url.base_url + url.getColorShift + objectToQueryString(payload?.params)
  //   )
  //     .then((res) => {
  //       payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       payload?.setLoading(false);

  //       cb(err?.response?.status);
  //     });
  // },

  FetchBrandStausUpdate: (payload, cb) => {
    getData(url.base_url + url.brandupdatestatusApi + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);

        // console.warn(err);
        // alert("Api error")
        // toast.error('Some Error Occoured2', {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   progress: undefined,
        //   bodyClassName: 'toastStyle',
        // });
      });
  },

  FetchStatusChange: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.updatestatuscategory + payload?.params)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
    payload?.setLoading(false);
    payload?.setStatus((prevStatus) => !prevStatus);
  },

  SubCategoryUpdateStatus: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.subcategoryupdatestatus + payload?.params)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
    payload?.setLoading(false);
    payload?.setStatus((prevStatus) => !prevStatus);
  },

  StatusUpdateCompanies: (payload, cb) => {
    getData(url.base_url + url.companystatusupdate + payload?.params)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
    payload?.setStatus((prevStatus) => !prevStatus);
  },

  // StatusUpdateBatches: (payload, cb) => {
  //   getData(url.base_url + url.batchStatusUpdate + payload?.params)
  //     .then((res) => {
  //       payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       payload?.setLoading(false);
  //       cb(err?.response?.status);
  //     });
  //   payload?.setStatus((prevStatus) => !prevStatus);
  // },

  HandleEditCompany: (payload, cb) => {
    const data = payload;
    PostRegisterData(url.base_url + url.companystatusupdate, data)
      .then((res) => {
        cb(res.data);

        toast.success("Company Update Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });

        window.location.href = "/company/all-company";
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
      });
  },

  FetchBrandEditt: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.brandupdatestatusApi, data)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        // toast.error('Some Error Occoured2', {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: false,
        //   draggable: true,
        //   progress: undefined,
        //   bodyClassName: 'toastStyle',
        // });
      });
  },

  // fetchEditCategories: (payload, cb) => {
  //   const data = payload;

  //   PostRegisterData(url.base_url + url.editingcategory + "/" + data.id, data)
  //     .then((res) => {
  //       toast.success("Category Update Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       if (err?.response?.data?.message === "Validation Error.") {
  //         const errorMessages = err?.response?.data?.data;

  //         if (errorMessages) {
  //           Object.keys(errorMessages).forEach((key) => {
  //             const messages = errorMessages[key];
  //             messages.forEach((message) => {
  //               toast.error(`${key}: ${message}`, {
  //                 position: "top-right",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: false,
  //                 draggable: true,
  //                 progress: undefined,
  //                 bodyClassName: "toastStyle",
  //               });
  //             });
  //           });
  //         }
  //       }
  //     });
  // },

  // fetchEditSMSReply: (payload, cb) => {
  //   const data = payload;

  //   PostRegisterData(url.base_url + url.editsmsReply + "/" + data.id, data)
  //     .then((res) => {
  //       toast.success("SMS Reply Update Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }

  //       if (err?.response?.data?.message === "Validation Error.") {
  //         const errorMessages = err?.response?.data?.data;

  //         if (errorMessages) {
  //           Object.keys(errorMessages).forEach((key) => {
  //             const messages = errorMessages[key];
  //             messages.forEach((message) => {
  //               toast.error(`${key}: ${message}`, {
  //                 position: "top-right",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: false,
  //                 draggable: true,
  //                 progress: undefined,
  //                 bodyClassName: "toastStyle",
  //               });
  //             });
  //           });
  //         }
  //       }
  //     });
  // },

  SubCategoryEditing: (payload, cb) => {
    const data = payload;

    PostRegisterData(
      url.base_url +
        url.subcategoryEdit +
        "/" +
        data.id +
        "?name=" +
        data.name +
        "&catId=" +
        data.catId +
        "&description=" +
        data.description
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }
      });
  },

  FetchAllRoleView: (payload, cb) => {
    getData(url.base_url + url.allRoleApi)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
      });
  },

  GetAllCounts: (payload, cb) => {
    // console.log(payload);
    let apiUrl = url.base_url + url.getAllCounts;

    if (payload?.company_id) {
      const queryParams = { company_id: payload?.company_id };
      apiUrl += `${objectToQueryString(queryParams)}`;
    }
    // console.log(apiUrl);

    getData(apiUrl)
      .then((res) => {
        cb(res?.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
        payload?.setLoading(false);
      });
  },

  GetAllRandom: (payload, cb) => {
    payload?.setLoading(true);
    // console.log(payload);
    const queryParams = payload?.params || { paginate: payload?.paginate };
    getData(
      url.base_url + url.getRandomCodes + objectToQueryString(queryParams)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  ExportCSVRadmonCOde: (payload, cb) => {
    if (payload.hasOwnProperty("paginate")) {
      delete payload.paginate;
    } else if (payload.hasOwnProperty("limit")) {
      delete payload.limit;
    }
    getData(
      `${url.base_url}${url.getRandomCodes}?paginate=all${
        payload?.random_code ? `&random_code=${payload.random_code}` : ""
      }${payload?.pair_value ? `&pair_value=${payload.pair_value}` : ""}${
        payload?.company ? `&company=${payload.company}` : ""
      }${payload?.batch_name ? `&batch_name=${payload.batch_name}` : ""}`
    )
      .then((res) => {
        cb(res.data);
        payload?.setExportCsvLoadings(false);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        payload?.setExportCsvLoadings(false);

        cb(err?.response?.status);
      });
  },

  //---------------------------------------------SMS--------------------------------------------------------------------

  // GetAllSMSLogs: (payload, cb) => {
  //   payload?.setLoading(true);
  //   getData(url.base_url + url.getSms + objectToQueryString(payload?.params))
  //     .then((res) => {
  //       payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       payload?.setLoading(false);
  //       cb(err?.response?.status);
  //     });
  // },

  FetchExportCSV: (payload, cb) => {
    if (payload.hasOwnProperty("paginate")) {
      delete payload.paginate;
    } else if (payload.hasOwnProperty("limit")) {
      delete payload.limit;
    }

    getData(
      `${url.base_url}${url.SmsReport}?paginate=all${
        payload.number ? `&number=${payload.number}` : ""
      }${payload.text ? `&text=${payload.text}` : ""}${
        payload.company_name ? `&company_name=${payload.company_name}` : ""
      }${payload.product_name ? `&product_name=${payload.product_name}` : ""}${
        payload.batch_name ? `&batch_name=${payload.batch_name}` : ""
      }`
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        if (err?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }
        cb(err?.response?.status);
      });
  },

  //Report SMS

  // GetAllSMSReport: (payload, cb) => {
  //   payload?.setLoading(true);
  //   getData(url.base_url + url.SmsReport + objectToQueryString(payload?.params))
  //     .then((res) => {
  //       payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       payload?.setLoading(false);
  //       cb(err?.response?.status);
  //     });
  // },

  // GetProgressive: (payload, cb) => {
  //   getData(url.Progressive + objectToQueryString(payload?.params))
  //     .then((res) => {
  //       // payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       //     payload?.setLoading(false);
  //       cb(err?.response?.status);
  //     });
  // },

  //qrcode CSV

  // FetchExportQRCSV: (payload, cb) => {
  //   if (payload.hasOwnProperty("paginate")) {
  //     delete payload.paginate;
  //   } else if (payload.hasOwnProperty("limit")) {
  //     delete payload.limit;
  //   }
  //   getData(
  //     `${url.base_url}${url.QrcodeReport}?paginate=all${
  //       payload.number ? `&number=${payload.number}` : ""
  //     }${payload.text ? `&text=${payload.text}` : ""}${
  //       payload.company_name ? `&company_name=${payload.company_name}` : ""
  //     }${payload.product_name ? `&product_name=${payload.product_name}` : ""}${
  //       payload.batch_name ? `&batch_name=${payload.batch_name}` : ""
  //     }`
  //   )
  //     .then((res) => {
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       cb(err?.response?.status);
  //     });
  // },

  //Report QRCODE

  // GetAllQrcodeReport: (payload, cb) => {
  //   payload?.setLoading(true);
  //   getData(
  //     url.base_url + url.QrcodeReport + objectToQueryString(payload?.params)
  //   )
  //     .then((res) => {
  //       payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       payload?.setLoading(false);
  //       cb(err?.response?.status);
  //     });
  // },

  // GetReportProblem: (payload, cb) => {
  //   payload?.setLoading(true);
  //   getData(
  //     url.base_url + url.ReportProblem + objectToQueryString(payload?.params)
  //   )
  //     .then((res) => {
  //       payload?.setLoading(false);
  //       cb(res.data);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }
  //       payload?.setLoading(false);
  //       cb(err?.response?.status);
  //     });
  // },

  // PostSMSReply: (payload, cb) => {
  //   // const NewData = new FormData();
  //   // NewData.append("title", payload.title);
  //   // NewData.append("message", payload.message);
  //   // NewData.append("urdu_message", payload.urdu_message);
  //   // NewData.append("company_id", payload.company_id);
  //   // NewData.append("product_id", payload.product_id);

  //   PostRegisterData(url.base_url + url.createsmsReply, payload)
  //     .then((res) => {
  //       cb(res.data);
  //       toast.success("SMS Reply Save Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       payload.setLoading(false);
  //       setTimeout(() => {
  //         window.location.href = "/sms/get-sms-reply";
  //       }, 1500);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }

  //       if (err?.response?.data?.message === "Validation Error.") {
  //         const errorMessages = err?.response?.data?.data;

  //         if (errorMessages) {
  //           Object.keys(errorMessages).forEach((key) => {
  //             const messages = errorMessages[key];
  //             messages.forEach((message) => {
  //               toast.error(`${key}: ${message}`, {
  //                 position: "top-right",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: false,
  //                 draggable: true,
  //                 progress: undefined,
  //                 bodyClassName: "toastStyle",
  //               });
  //             });
  //           });
  //         }
  //       }

  //       cb(err?.response?.status);
  //       toast.error(err?.response?.data?.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       payload.setLoading(false);
  //     });
  // },

  // PostRandomCode: (payload, cb) => {
  //   const NewData = new FormData();

  //   NewData.append("digit_count", payload.digit_count);
  //   NewData.append("user_id", payload.user_id);
  //   NewData.append("product_id", payload.product_id);
  //   NewData.append("company_id", payload.company_id);
  //   NewData.append("range", payload.range);
  //   NewData.append("segment", payload.segment);
  //   NewData.append("RequestID", payload.RequestID);

  //   // const encryptedUID = CryptoJS.AES.encrypt(
  //   //   payload.RequestID
  //   // ).toString();

  //   PostRegisterData(url.base_url + url.postRandomCodes, NewData)
  //     .then((res) => {
  //       cb(res.data);

  //       toast.success("Codes Generated Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });

  //       payload.setLoading(false);
  //       setTimeout(() => {
  //         window.location.href = "/random/all-random-code";
  //       }, 1500);

  //       const encryptedToken = CryptoJS.AES.encrypt(
  //         payload.RequestID,
  //         url.encryptionSecret
  //       ).toString();
  //       localStorage.setItem("uid", encryptedToken);
  //       // localStorage.setItem("uid", payload.RequestID);

  //       // localStorage.setItem("uid", encryptedUID);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }

  //       if (err?.response?.data?.message === "Validation Error.") {
  //         const errorMessages = err?.response?.data?.data;

  //         if (errorMessages) {
  //           Object.keys(errorMessages).forEach((key) => {
  //             const messages = errorMessages[key];
  //             messages.forEach((message) => {
  //               toast.error(`${key}: ${message}`, {
  //                 position: "top-right",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: false,
  //                 draggable: true,
  //                 progress: undefined,
  //                 bodyClassName: "toastStyle",
  //               });
  //             });
  //           });
  //         }
  //       }

  //       cb(err?.response?.status);
  //       toast.error(err?.response?.data?.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       payload.setLoading(false);
  //     });
  // },

  // PostBatch: (payload, cb) => {
  //   const NewData = new FormData();

  //   NewData.append("batch_name", payload.batch_name);
  //   NewData.append("serial_start_rng", payload.serial_start_rng);
  //   NewData.append("serial_end_rng", payload.serial_end_rng);
  //   NewData.append("manufacturing_date", payload.manufacturing_date);
  //   NewData.append("expiry_date", payload.expiry_date);
  //   NewData.append("product_id", payload.product_id);
  //   NewData.append("remarks", payload.remarks);
  //   NewData.append("company_id", payload.company_id);

  //   PostRegisterData(url.base_url + url.postBatchAPI, NewData)
  //     .then((res) => {
  //       cb(res.data);
  //       toast.success("Batch Registered Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       payload.setLoading(false);
  //       setTimeout(() => {
  //         window.location.href = "/batch/all-batch";
  //       }, 1500);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //         window.location.reload();
  //       }

  //       if (err?.response?.data?.message === "Validation Error.") {
  //         const errorMessages = err?.response?.data?.data;

  //         if (errorMessages) {
  //           Object.keys(errorMessages).forEach((key) => {
  //             const messages = errorMessages[key];
  //             messages.forEach((message) => {
  //               toast.error(`${key}: ${message}`, {
  //                 position: "top-right",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: false,
  //                 draggable: true,
  //                 progress: undefined,
  //                 bodyClassName: "toastStyle",
  //               });
  //             });
  //           });
  //         }
  //       }

  //       cb(err?.response?.status);
  //       toast.error(err?.response?.data?.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       payload.setLoading(false);
  //     });
  // },

  PostUpdateBatch: (payload, cb) => {
    const NewData = new FormData();

    NewData.append("id", payload.id);
    NewData.append("batch_name", payload.batch_name);
    NewData.append("serial_start_rng", payload.serial_start_rng);
    NewData.append("serial_end_rng", payload.serial_end_rng);
    NewData.append("manufacturing_date", payload.manufacturing_date);
    NewData.append("expiry_date", payload.expiry_date);
    NewData.append("remarks", payload.remarks);
    NewData.append("product_id", payload.product_id);
    NewData.append("company_id", payload.company_id);

    PostRegisterData(url.base_url + url.postUpdateBatchAPI, NewData)
      .then((res) => {
        cb(res.data);
        toast.success("Batch Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/batch/all-batch";
        }, 1500);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated.") {
          localStorage.clear();
          window.location = "/";
          window.location.reload();
        }

        if (err?.response?.data?.message === "Validation Error.") {
          const errorMessages = err?.response?.data?.data;

          if (errorMessages) {
            Object.keys(errorMessages).forEach((key) => {
              const messages = errorMessages[key];
              messages.forEach((message) => {
                toast.error(`${key}: ${message}`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  bodyClassName: "toastStyle",
                });
              });
            });
          }
        }

        cb(err?.response?.status);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          bodyClassName: "toastStyle",
        });
        payload.setLoading(false);
      });
  },

  // PostUpdateColorShift: (payload, cb) => {
  //   const NewData = new FormData();

  //   NewData.append("id", payload.id);
  //   NewData.append("title", payload.title);
  //   NewData.append("image", payload.image);
  //   NewData.append("subtitle", payload.subtitle);

  //   PostRegisterData(url.base_url + url.ColorShift, NewData)
  //     .then((res) => {
  //       cb(res.data);
  //       toast.success("ColorShift Updated Successfully", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       // payload.setLoading(false);
  //       setTimeout(() => {
  //         window.location.href = "/DomainParameter/get-colorShifting";
  //       }, 1500);
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.message === "Unauthenticated.") {
  //         localStorage.clear();
  //         window.location = "/";
  //       }

  //       if (err?.response?.data?.message === "Validation Error.") {
  //         const errorMessages = err?.response?.data?.data;

  //         if (errorMessages) {
  //           Object.keys(errorMessages).forEach((key) => {
  //             const messages = errorMessages[key];
  //             messages.forEach((message) => {
  //               toast.error(`${key}: ${message}`, {
  //                 position: "top-right",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: false,
  //                 draggable: true,
  //                 progress: undefined,
  //                 bodyClassName: "toastStyle",
  //               });
  //             });
  //           });
  //         }
  //       }

  //       cb(err?.response?.status);
  //       toast.error(err?.response?.data?.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         bodyClassName: "toastStyle",
  //       });
  //       // payload.setLoading(false);
  //     });
  // },
};

function PostData(URL, data) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(URL, data, config);
}
export function JsonPostData(URL, data) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getNewTokenn}`,
    },
  };
  return axios.post(URL, data, config);
}
function PostRegisterData(URL, data) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${NewToekn}`,
      // "Access-Control-Allow-Origin": "*"
    },
  };
  return axios.post(URL, data, config);
}

// axios.defaults.headers.common = {'Authorization': `bearer ${token}`}
function getData(URL) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${NewToekn}`,
    },
  };
  return axios.get(URL, config);
}
