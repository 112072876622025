// project-imports
import dashboard from "./dashboard";

import roles from "./roles";
import Notifiicationn from "./notificationcreate";
// import support from './support';
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
// import pages from './pages';
import permissions from "./permissions";
import domainParameter from "./domainParameter";
import property from "./property";
import flat from "./flat";
import clients from "./clients";
import allAssignPermissions from "./allAssignPermissions";
import AllPayments from "./Payments";
import users from "./allusers";
import maintenance from "./maintenanceType";
import reports from "./allReports";

// ==============================|| MENU ITEMS ||============================== //
let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
}

const permissionsAssrray = decryptedData && JSON.parse(decryptedData);
// console.log("dhhcontatatdhdhdh", permissionsAssrray?.user_type);
const menuItems = {
  items: [
    ...(permissionsAssrray?.user_type === "bank_user"
      ? []
      : dashboard
      ? [dashboard]
      : []),
    // ...(companies ? [companies] : []),

    ...(domainParameter ? [domainParameter] : []),
    ...(property ? [property] : []),
    ...(flat ? [flat] : []),
    ...(clients ? [clients] : []),
    ...(AllPayments ? [AllPayments] : []),
    ...(roles ? [roles] : []),
    // ...(Notifiicationn ? [Notifiicationn] : []),
    ...(reports ? [reports] : []),
    ...(allAssignPermissions ? [allAssignPermissions] : []),
    ...(users ? [users] : []),
    ...(maintenance ? [maintenance] : []),

  ].filter(Boolean),
};

export default menuItems;
