// type
import { ShopRemove } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import { MdHome } from "react-icons/md";
import { BsBuildingFillAdd } from "react-icons/bs";
import { TbRulerMeasure } from "react-icons/tb";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbHomeCog } from "react-icons/tb";
import { MdOutlineHomeWork } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { IoMdNotifications } from "react-icons/io";
import { GrHostMaintenance } from "react-icons/gr";
import {
  can_add_property_type,
  can_edit_property_type,
  can_view_property_type,
  can_add_amenities,
  can_edit_amenities,
  can_view_amenities,
  can_add_tax,
  can_edit_tax,
  can_view_tax,
  can_add_premises_size,
  can_edit_premises_size,
  can_view_premises_size,
  can_add_maintenance_type,
  can_view_maintenance_type,
  can_edit_maintenance_type,
  can_view_notifications,
  can_delete_notifications,
} from "../helper/permissionsCheck";
// icons
const icons = {
  navigation: MdHome,
  propertyType: TbHomeCog,
  amenities: BsBuildingFillAdd,
  flatSize: TbRulerMeasure,
  taxes: HiOutlineReceiptTax,
  notifications: IoMdNotifications,
  property: MdOutlineHomeWork,
  color: MdHome,
  shadow: MdHome,
  clients: FiUsers,
  maintenance: GrHostMaintenance,
};
// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    console.log(error, "er");
  }
}

const permissions = decryptedData
  ? JSON.parse(decryptedData)?.permissions
  : null;

const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];

const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};
// ==============================|| MENU ITEMS - roles ||============================== //

// const domainParameter = permissionsAccessFunction('company') ? {
const domainParameter =
  can_add_property_type ||
  can_edit_property_type ||
  can_view_property_type ||
  can_add_amenities ||
  can_edit_amenities ||
  can_view_amenities ||
  can_add_premises_size ||
  can_edit_premises_size ||
  can_view_premises_size ||
  can_add_tax ||
  can_edit_tax ||
  can_view_tax ||
  can_view_notifications ||
  can_add_maintenance_type ||
  can_view_maintenance_type ||
  can_edit_maintenance_type
    ? {
        id: "domain-parameter",
        title: "Domain Parameter",
        icon: icons.navigation,
        type: "group",
        children: [
          // allowedPermissions.includes('get-company') ? {
          can_add_property_type ||
          can_edit_property_type ||
          can_view_property_type
            ? {
                id: "propertyType",
                title: "Property Type",
                type: "item",
                url: "/propertyType/all-type",
                icon: icons.propertyType,
                breadcrumbs: true,
              }
            : null,
          can_add_amenities || can_edit_amenities || can_view_amenities
            ? {
                id: "amenities",
                title: "Amenities",
                type: "item",
                url: "/amenities/all-amenities",
                icon: icons.amenities,
                breadcrumbs: true,
              }
            : null,
          can_add_premises_size ||
          can_edit_premises_size ||
          can_view_premises_size
            ? {
                id: "PremisesSize",
                title: "Flat/Shop Size",
                type: "item",
                url: "/premises-size/all-premises-size",
                icon: icons.flatSize,
                breadcrumbs: true,
              }
            : null,
          can_add_tax || can_edit_tax || can_view_tax
            ? {
                id: "taxes",
                title: "Taxes",
                type: "item",
                url: "/taxes/all-taxes",
                icon: icons.taxes,
                breadcrumbs: true,
              }
            : null,
          can_view_notifications
            ? {
                id: "notifications",
                title: "All Notifications",
                type: "item",
                url: "/notifications/all-notifications",
                icon: icons.notifications,
                breadcrumbs: true,
              }
            : null,

          can_add_maintenance_type ||
          can_view_maintenance_type ||
          can_edit_maintenance_type
            ? {
                id: "all-maintenance",
                title: "Maintenance Type",
                type: "item",
                url: "/maintenance/all-maintenance-type",
                icon: icons.maintenance,
                breadcrumbs: true,
              }
            : null,
        ].filter(Boolean),
      }
    : null;

export default domainParameter;
