import { lazy, useEffect } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import {
  can_add_property_type,
  can_view_property_type,
  can_edit_property_type,
  can_add_amenities,
  can_edit_amenities,
  can_view_amenities,
  can_add_tax,
  can_edit_tax,
  can_view_tax,
  can_add_property,
  can_edit_property,
  can_view_property,
  can_add_flat,
  can_edit_flat,
  can_view_flat,
  can_add_premises_size,
  can_edit_premises_size,
  can_view_premises_size,
  can_add_clients,
  can_edit_clients,
  can_view_clients,
  can_add_payments,
  can_edit_payments,
  can_view_payments,
  can_add_assign_premises,
  can_edit_assign_premises,
  can_view_assign_premises,
  can_add_roles,
  can_edit_roles,
  can_view_roles,
  can_add_users,
  can_edit_users,
  can_view_users,
  can_add_permissions,
  can_view_notifications,
  can_delete_notifications,
  can_add_maintenance_type,
  can_view_maintenance_type,
  can_edit_maintenance_type,
  can_add_maintenance,
  can_view_maintenance,
  can_edit_maintenance,
  can_delete_maintenance,
} from "helper/permissionsCheck";
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));
// render - data display components
const AllPropertyType = Loadable(
  lazy(() => import("pages/propertyType/AllPropertyType"))
);
const AllAmenitiesType = Loadable(
  lazy(() => import("pages/amenities/AllAmenitiesType"))
);
const AllFlatsize = Loadable(lazy(() => import("pages/flatSize/AllFlatsize")));
const AllTaxes = Loadable(lazy(() => import("pages/taxes/AllTaxes")));
const AllProperty = Loadable(lazy(() => import("pages/property/AllProperty")));
const AllFlat = Loadable(lazy(() => import("pages/flat/AllFlat")));
const AllFlatProperty = Loadable(
  lazy(() => import("pages/flat/AllFlatProperty"))
);
const CreateFlat = Loadable(lazy(() => import("pages/flat/CreateFlat")));
const ViewDetails = Loadable(lazy(() => import("pages/flat/DetailsFlat")));
const Allclients = Loadable(lazy(() => import("pages/clients/Allclients")));
const ClientDetails = Loadable(
  lazy(() => import("pages/clients/ClientDetails"))
);
const CreateClients = Loadable(lazy(() => import("pages/clients/crclients")));
const AlllAssigPermissions = Loadable(
  lazy(() => import("pages/assignpermission/allassingpermissions"))
);
const CreateAssignPermission = Loadable(
  lazy(() => import("pages/assignpermission/createassignpermission"))
);
const AllAssPremiDetails = Loadable(
  lazy(() => import("pages/assignpermission/allassigndetails"))
);
const AllPayments = Loadable(lazy(() => import("pages/payments/AllPayments")));
const CreatePayments = Loadable(
  lazy(() => import("pages/payments/CreatePayments"))
);
const AllNotifications = Loadable(
  lazy(() => import("pages/notification/AllNotifications"))
);

const AllRoles = Loadable(lazy(() => import("pages/roles/allroles")));
const AllMaintenance = Loadable(
  lazy(() => import("pages/maintenance/AllMaintenance_Type"))
);
const Maintenance = Loadable(
  lazy(() => import("pages/maintenance/AllMaintanance"))
);
const CreateMaintenance = Loadable(
  lazy(() => import("pages/maintenance/CreateMaintenance.js"))
);

const RegisterUser = Loadable(
  lazy(() => import("pages/user-management/registerUser"))
);

const PermissionsAssign = Loadable(
  lazy(() => import("pages/permissions/assign"))
);
const AllUsers = Loadable(lazy(() => import("pages/user-management/allusers")));
const AllReports = Loadable(lazy(() => import("pages/reports/AllReports.js")));
const ReportByclient = Loadable(lazy(() => import("pages/reports/reportbyclient")));
const ReportByPayment = Loadable(lazy(() => import("pages/reports/reportbypayment")));
const ReportByMonth = Loadable(lazy(() => import("pages/reports/reportbymonth")));
const ReportByProperty = Loadable(lazy(() => import("pages/reports/reportbyproperty")));
const ReportByFlatsShops = Loadable(lazy(() => import("pages/reports/reportbypremises")));





// pages routing

// ==============================|| MAIN ROUTES ||============================== //
// let permissionUser = localStorage.getItem('LoginData');
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
    // ...
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
}

const permissionsAssrray =
  decryptedData && JSON.parse(decryptedData)?.user_type;

// useEffect(() => {
//   window.location.href = "/payments/all-payments";
// }, [permissionsAssrray]);

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children:
        permissionsAssrray == "bank_user"
          ? []
          : [
              {
                path: "/",
                element: <DashboardDefault />,
              },
              {
                path: "dashboard",
                element: <DashboardDefault />,
              },
              {
                path: "*",
                element: <DashboardDefault />,
              },
            ],
    },

    can_add_property_type || can_edit_property_type || can_view_property_type
      ? {
          path: "propertyType",
          element: <MainLayout />,
          children: [
            can_add_property_type ||
            can_edit_property_type ||
            can_view_property_type
              ? {
                  path: "all-type",
                  element: <AllPropertyType />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "amenities",
          element: <MainLayout />,
          children: [
            // allowedPermissions.includes('get-company')
            can_add_amenities || can_view_amenities || can_edit_amenities
              ? {
                  path: "all-amenities",
                  element: <AllAmenitiesType />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "premises-size",
          element: <MainLayout />,
          children: [
            can_add_premises_size ||
            can_edit_premises_size ||
            can_view_premises_size
              ? {
                  path: "all-premises-size",
                  element: <AllFlatsize />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "taxes",
          element: <MainLayout />,
          children: [
            can_add_tax || can_edit_tax || can_view_tax
              ? {
                  path: "all-taxes",
                  element: <AllTaxes />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,
    true
      ? {
          path: "notifications",
          element: <MainLayout />,
          children: [
            can_view_notifications
              ? {
                  path: "all-notifications",
                  element: <AllNotifications />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    can_add_property || can_edit_property || can_view_property
      ? {
          path: "property",
          element: <MainLayout />,
          children: [
            true
              ? {
                  path: "all-property",
                  element: <AllProperty />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    can_add_flat || can_edit_flat || can_view_flat
      ? {
          path: "flat",
          element: <MainLayout />,
          children: [
            // allowedPermissions.includes('get-company')
            can_add_flat || can_edit_flat || can_view_flat
              ? {
                  path: "all-flat",
                  element: <AllFlat />,
                }
              : null,
            can_add_flat || can_edit_flat || can_view_flat
              ? {
                  path: "flat-property",
                  element: <AllFlatProperty />,
                }
              : null,
            can_add_flat
              ? {
                  path: "create-flat",
                  element: <CreateFlat />,
                }
              : null,
            can_view_flat
              ? {
                  path: "details-flat",
                  element: <ViewDetails />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "clients",
          element: <MainLayout />,
          children: [
            can_add_clients || can_edit_clients || can_view_clients
              ? {
                  path: "all-clients",
                  element: <Allclients />,
                }
              : null,
            true
              ? {
                  path: "create-client",
                  element: <CreateClients />,
                }
              : null,
            true
              ? {
                  path: "details",
                  element: <ClientDetails />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,
    true
      ? {
          path: "premises",
          element: <MainLayout />,
          children: [
            can_add_assign_premises ||
            can_edit_assign_premises ||
            can_view_assign_premises
              ? {
                  path: "all-assign-premises",
                  element: <AlllAssigPermissions />,
                }
              : null,
            can_add_assign_premises
              ? {
                  path: "create-assign-premises",
                  element: <CreateAssignPermission />,
                }
              : null,
              can_edit_assign_premises
              ? {
                  path: "details-assign-premises",
                  element: <AllAssPremiDetails />,
                }
                :null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "payments",
          element: <MainLayout />,
          children: [
            can_add_payments || can_edit_payments || can_view_payments
              ? {
                  path: "all-payments",
                  element: <AllPayments />,
                }
              : null,
            true
              ? {
                  path: "create-payments",
                  element: <CreatePayments />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,


      true
      ? {
          path: "reports",
          element: <MainLayout />,
          children: [
            true
              ? {
                  path: "all-reports",
                  element: <AllReports />,
                }
              : null,

              true
              ? {
                  path: "report-by-client",
                  element: <ReportByclient />,
                }
              : null,
              true
              ? {
                  path: "report-by-payment",
                  element: <ReportByPayment />,
                }
              : null,
              true
              ? {
                  path: "report-by-month",
                  element: <ReportByMonth />,
                }
              : null,
              true
              ? {
                  path: "report-by-flats-shops",
                  element: <ReportByFlatsShops />,
                }
              : null,
              true
              ? {
                  path: "report-by-property",
                  element: <ReportByProperty />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,







    true
      ? {
          path: "roles",
          element: <MainLayout />,
          children: [
            can_add_roles || can_edit_roles || can_view_roles
              ? {
                  path: "all-roles",
                  element: <AllRoles />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    can_add_permissions
      ? {
          path: "permissions",
          element: <MainLayout />,
          children: [
            can_add_permissions
              ? {
                  path: "all-permissions",
                  element: <PermissionsAssign />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "users",
          element: <MainLayout />,
          children: [
            // allowedPermissions.includes('get-company')
            can_add_users || can_edit_users || can_view_users
              ? {
                  path: "all-users",
                  element: <AllUsers />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "users",
          element: <MainLayout />,
          children: [
            can_add_users
              ? {
                  path: "register-users",
                  element: <RegisterUser />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "maintenance",
          element: <MainLayout />,
          children: [
            can_add_maintenance_type ||
            can_view_maintenance_type ||
            can_edit_maintenance_type
              ? {
                  path: "all-maintenance-type",
                  element: <AllMaintenance />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,

    true
      ? {
          path: "maintenance",
          element: <MainLayout />,
          children: [
            can_add_maintenance || can_edit_maintenance || can_view_maintenance
              ? {
                  path: "all-maintenance",
                  element: <Maintenance />,
                }
              : null,
            true
              ? {
                  path: "create-maintenance",
                  element: <CreateMaintenance />,
                }
              : null,
          ].filter(Boolean),
        }
      : null,
  ].filter(Boolean),
};

export default MainRoutes;
