// type
import { ShopRemove } from 'iconsax-react';
import { AES, enc } from 'crypto-js';
import * as url from "../store/constant/Endpoints";
import { MdHome } from "react-icons/md";
import { BsBuildingFillAdd } from "react-icons/bs";
import { TbRulerMeasure } from "react-icons/tb";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbHomeCog } from "react-icons/tb";
import { MdOutlineHomeWork } from "react-icons/md";
import { MdOutlineBedroomParent } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { HiUsers } from "react-icons/hi2";
import {
  can_add_users,can_edit_users,can_view_users ,
} from "helper/permissionsCheck";
// icons
const icons = {
  navigation: MdHome,
  flatType: TbHomeCog,
  amenities: BsBuildingFillAdd,
  flatSize: TbRulerMeasure,
  taxes: HiOutlineReceiptTax,
  flat: MdOutlineBedroomParent,
  assignPermission: MdOutlineSecurity,
  color: MdHome,
  shadow: MdHome,
  AllUsers:HiUsers
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

let permissionUser = localStorage.getItem('data');

let decryptedData = null;
if (permissionUser) {
  try {
    // Code that may throw an exception
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(enc.Utf8);
    // ...
  } catch (error) {
    console.log(error, "er")
  }
}

const permissions = decryptedData ? JSON.parse(decryptedData)?.permissions : null;

const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map(permission => permission?.permission) : [];

const permissionsAccessFunction = (brand) => {
  return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some(permission => permission.includes(brand));
}
// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const flat = (can_add_users||can_edit_users||can_view_users) ? {
  id: 'ddall-permissions',
  title: 'USER Management',
  icon: icons.navigation,
  type: 'group',
  children: [

    // allowedPermissions.includes('get-company') ? {


    (can_add_users||can_edit_users||can_view_users) ? {
      id: 'all-users',
      title: 'All Users',
      type: 'item',
      url: '/users/all-users',
      icon: icons.AllUsers ,
      breadcrumbs: true
    } : null,


    can_add_users ? {
        id: 'users',
        title: 'Users',
        type: 'item',
        url: '/users/register-users',
        icon: icons.AllUsers,
        breadcrumbs: true
      } : null,
  ].filter(Boolean)
} : null;

export default flat;
